<template>
    <div class="page">
      <ProfileAdmin :userId="$route.params.id"/>
    </div>
</template>

<script>
import ProfileAdmin from '@/components/admins/profile/ProfileAdmin';

export default {
  name: 'admin-id',
  components: {
    ProfileAdmin,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
